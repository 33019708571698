export default {
  headers: {
    index: '首页',
    message: '公告消息',
    query: '物流查询',
    login: '登录',
    register: '注册',
    logout: '登出',
    member: '集运中心'
  },
  utils: {
    title: "登出确认",
    confirm: "重新登录",
    cancel: "取消",
    message: "您的登录已过期，可以取消返回首页，也可以重新登录"
  },
  advantageBox: {
    team: {
      title: '专业团队',
      subtitle1: '拥有经验丰富的专业团队',
      subtitle2: '提供全方面的售后服务'
    },
    qualityControl: {
      title: '质量控制和安全保障',
      subtitle1: '创新控制和安全保障，每一个环节都进行严格的质量控制',
      subtitle2: '确保货物的安全和完整'
    },
    innovativeTechnology: {
      title: '创新科技应用',
      subtitle1: '采用先进的集运系统提高运营效率，实时跟踪货物状态',
      subtitle2: '确保安全快速达到目的地'
    },
    diverseService: {
      title: '服务多样化',
      subtitle1: '灵活的运输选项',
      subtitle2: '可定制的包装方案'
    }
  },
  immediate: {
    title: "到件即发",
    placeholderTimer: "请选择时效",
    timer: "时效",
    channel: "渠道",
    placeholderChannel: "请选择渠道",
    rem: "备注",
    placeholderRem: "如有其他需求或有特别需要注意的,请留下您的需求备注信息",
    submit: "提交",
    submitSuccess: "提交成功！",
    addWarning: "添加数量已达上限！"
  },
  exhibitBox: {
    upper: {
      members: '10000+ 会员',
      dailyGoodsWarehousing: '每日货物入仓 30000+',
      dailyGoodsStorage: '每日货物入仓 30000+',
      dailyOrders: '每日订单 10000+',
      dailyShipments: '每日发货 10000+'
    },
    lower: {
      dailyShipments: '每日发货 10000+',
      cooperativePickupPoints: '合作自提点 600+'
    }
  },
  toolBox: {
    teach: {
      title: '集运教学',
      subtitle: '图文及视频教学指南'
    },
    volume: {
      title: '体积试算',
      subtitle: '货物体积重量试算'
    },
    shipping: {
      title: '运费试算',
      subtitle: '订单运费试算'
    },
    chargeIntroduction: {
      title: '收费介绍',
      subtitle: '收费介绍及相关说明'
    }
  },
  introduce: {
    destination: '目的地',
    CalculationModeName: '计算模式',
    CarryModeName: '进位模式',
    CarrierNames: '承运商',
    GoodsTypeNames: '货物类型',
    TimerNames: '时效',
    range: '范围',
    firstWeight: '首重',
    unitPrice: '单价',
    surchargeFormula: '附加费(Z表示重量)',
    unit: '单位',
    FirstWeight: "首重重量",
    FirstPrice: "首重价格",
    FirstSurcharge: "首重附加费"
  },
  paymentCard: {
    paymentMethod: '支付方式',
    wechatPay: '微信支付',
    alipayPay: '支付宝支付',
    balancePay: '余额支付',
    paymentAmount: '支付金额',
    rechargeAmount: "充值金额",
    paidInAmount: "实付金额"
  },
  QrCodePay: {
    paymentAmount: '支付金额',
    orderNumber: '订单号',
    orderDescription: '订单描述',
    bankDeposit: "开户银行",
    openingName: "开户姓名",
    account: "账户",
    prompt: "上传支付凭证",
    transactionNo: "交易流水号",
    onSubmit: "提交申请",
    placeholderTransactionNo: "请填写交易流水号",
    placeholderOffline: "请上传交易凭证",
    successMsg: "提交成功，请等待管理人员审核！",
    rem: "备注"
  },
  agent: {
    title: "成为代理收益不断",
    referralCode: "我的推荐码",
    download: "下载代理二维码",
    copyLink: "复制代理链接",
    WithdrawalMoney: "当前代理收益",
    promotionNumber: "代理会员数量",
    withdrawal: "提现收益到余额",
    member: "会员明细",
    id: "会员编号",
    name: "会员名称",
    createTime: "注册时间",
    lastLoginTime: "最近活跃时间",
    withdrawalIncome: "可提现收益",
    allWithdrawal: "全部提现",
    label: "输入提现金额：",
    submit: "确定提现",
    placeholder: "请输入提现金额",
    success: "提现成功！",
    recommend: {
      title: "把我们推荐给你的好友",
      referralCode: "我的推荐码",
      download: "下载代理二维码",
      copyLink: "复制代理链接",
      promotionNumber: "推广会员数量",
      member: "会员明细",
      id: "会员编号",
      name: "会员名称",
      createTime: "注册时间",
      lastLoginTime: "最近活跃时间",
    }
  },
  flow: {
    title: "消费总金",
    residue: "剩余可用",
    thisWeek: "本周消费",
    today: "今日消费金",
    placeholderTransactionNo: "请输入流水号",
    placeholderWalletLogType: "消费类型",
    search: "查询",
    TypeName: "交易类型",
    CreateTime: "交易时间",
    VariableState: "状态",
    Money: "变动金",
    OldMoney: "变动前",
    OrderNo: "系统单号",
    TransactionNo: "交易流水号",
    Rem: "备注说明",
    Balance: "余额",
    Integral: "积分"
  },
  comment: {
    title: "请写出您对运输/送货服务的总体满意程度",
    prompt: "写您的评论",
    placeholder: "请输入内容",
    veryPoor: "极差",
    disappointed: "失望",
    average: "一般",
    satisfied: "满意",
    verySatisfied: "非常满意",
    callback: "感谢您的评价",
    mainTitle: "为什么选择我们？",
    subTitle: "看其他伙伴怎么说",
    reply: "商家回复"
  },
  cashier: {
    paymentMethod: '支付方式',
    payNow: '立即支付',
    cancel: '取消',
    confirmBalance: '是否使用余额支付￥({Receivables})吗？"',
    prompt: "提示",
    confirm: "确认",
    cancelDelete: "已取消支付",
    successPayment: "支付成功",
    warning: "请选择支付方式"
  },
  footer: {
    domesticConsolidation: '集运流程相关',
    domesticDeliveryService: '香港派送服务',
    domesticStorePickup: '香港门市自取',
    more: '更多...',
    overseasConsolidation: '海外集运',
    overseasDeliveryService: '海外派送服务',
    overseasStorePickup: '海外门市自取',
    noviceTeach: '新手教学',
    frequentlyQuestion: '常见问题',
    freightCalculation: '运费计算',
    earnPoints: '帮助中心',
    cashRoll: '如何获得优惠券',
    payment: '如何支付',
    companyProfile: '关于我们',
    declarationProtection: '隐私权保护声明',
    privacyStatement: '应用程序隐私说明',
    disclaimers: '使用条款及免责声明',
    acceptCarriage: '承运条约',
    contactUs: '联系我们',
    filings: '@ 2023 战北 版权所有'
  },
  download: {
    ios: '安装iOS',
    android: '安装安卓'
  },
  mergerCard: {
    popover: {
      CarryWeight: "计费重量",
      FirstWeight: "首重",
      FirstPrice: "首重价格",
      FirstWeightSurcharge: "首重附加费",
      LastWeight: "续重",
      LastWeightUnitPrice: "续重单价",
      LastWeightSurcharge: "续重附加费"
    },
    Channel: "渠道",
    placeholderChannel: "请选择渠道",
    VolumeWeight: "体积重量",
    totalVolumeWeight: "总体积重量",
    yes: "是",
    no: "否",
    delivery: "运输方式",
    packageInfo: "包裹信息",
    addressInfo: "地址信息",
    orderInfo: "货物信息",
    discountInfo: "优惠选项",
    otherInfo: "其他选项",
    placeholderAdded: "请选择增值服务",
    cashier: "收银台",
    validateIntegral: "积分超出所拥有的额度",
    preferential: "可优惠",
    servicePrice: "服务费",
    Discount: "折扣率",
    InsuredCommission: "保价手续费",
    AgenServiceFree: "自提点服务费",
    CouponAmount: "优惠券金额",
    IntegralAmount: "积分抵扣",
    AddedServicesFee: "增值服务费用",
    TaxCommission: "包税手续费",
    CollectionCommission: "代收货款手续费",
    DestinationCharges: "目的地费用",
    AccruedCharge: "应付费用",
    DeclareAccount: "申报数量",
    Size: "尺寸",
    Weight: "重量",
    step1: '包裹信息',
    step2: '订单配送',
    step3: '订单信息',
    step4: '下单付款',
    ServiceType: '服务方式',
    placeholderServiceType: '请选择服务方式',
    Code: '快递单号',
    Amount: '数量',
    GoodName: '品名',
    GoodsTypeName: '货物类型',
    Surcharge: '附加费（元）',
    totalCount: '总数量',
    totalWeight: '总重量',
    totalSurcharge: '附加费',
    placeholderGoodsTypeName: '请选择货物类型',
    Carrier: '乘运商',
    placeholderCarrier: '请选择乘运商',
    Timer: '时效',
    placeholderTimer: '请选择时效',
    AddedService: '增值服务',
    placeholderAddedService: '请选择增值服务',
    Integral: '积分',
    available: '可用',
    Coupon: '优惠券',
    placeholderCoupon: '请选择优惠券',
    ClientAddress: '配送至',
    placeholderClientAddress: '请选择地址',
    CollectionAmount: '代收货款',
    InsuredAmount: '保价金额',
    IsTax: '是否包税',
    placeholderIsTax: '请选择是否包税',
    DeclareName: '申报人',
    DeclarePhone: '申报手机',
    DeclareAddress: '申报地址',
    DeclareIdCard: '申报统编',
    DeclarePostCode: '申报邮编',
    DeclareEmail: '申报邮箱',
    DeclareTelephone: '申报电话',
    DeclareMoney: '申报总金额',
    DeclarePrice: '申报单价',
    GoodsName: '货物品名',
    GoodsName_En: '英文品名',
    feeDetail: '费用明细',
    weight: '重量',
    Freight: '运费',
    FreightDetail: '运费计算明细',
    CalculateFeeSurcharge: '包裹附加费',
    CalculateFeeAddressSurcharge: '地址附加费',
    CalculateFeeStorageCharges: '仓储费',
    CalculateFeeLengthExceed: '超长费',
    packageCount: '包裹数量',
    CalculateFeeWeightExceed: '超重费',
    paymentMethod: '付款方式',
    back: '上一步',
    next: '下一步',
    settlement: '结  算',
    placeholderGoodsName_En: '请输入英文品名',
    placeholderGoodsName: '请输入货物品名',
    validatePostalCode: '请输入有效的中国大陆邮政编码',
    validateLandline: '请输入有效的中国大陆座机号码',
    validateEmail: '请输入有效的邮箱地址',
    validateMobile: '请输入有效的中国大陆手机号',
    validateCreditCode: '请输入统编或身份证',
    paySuccess: '支付成功！！！',
    payInfo: '已取消支付！！！，请在订单列表支付',
    orderSuccess: '下单成功！正在调用支付...'
  },
  route: {
    index: '首页',
    member: '会员中心',
    personal: '个人中心总览',
    account: '个人信息设置',
    flow: '消费记录',
    coupon: '我的优惠券',
    notify: '通知设置',
    ConsolidatedDomestic: '合并货物',
    OrderDomestic: '我的订单',
    AddressDomestic: '收货地址',
    declare: '货物申报',
    claimDomestic: '申请预报',
    chargeIntroduce: '收费介绍',
    service: '收费及服务',
    agent: "代理中心",
    recommend: "推荐好友",
    help: 'help',
    Consolidation: '集运须知',
    problem: '常见问题',
    contraband: '运输违禁品',
    VolumeConversion: '体积计算',
    ShippingCalculation: '运费计算',
    teach: '新手教学',
    lostHandle: '失件处理',
    precautions: '注意事项',
    message: '最新消息',
    logistics: '物流查询',
    pickupPoint: '自提点供应',
    news: '公告详情',
    procedureDomestic: '国内集运',
    Login: '登录',
    Register: '注册',
    added: '增值服务类型',
    addedList: '增值服务列表',
    shopeeShop: '店铺管理',
    shopeeOrder: '订单管理',
    immediate: "到件即发",
    upload: "同行导入",
    lineDomestic: "专线下单",
    OrderLine: "专线订单"
  },
  upload: {
    save: "保存",
    selectFile: "选择导入文件",
    downloadTemplate: "下载模板文件",
    delete: "删除",
    loadingText: "拼命加载中",
    errorMsg: "数据异常消息",
    mainBillCode: "订单号",
    carrierBillCode: "承运单号",
    subbillNumber: "子单号",
    parcelCode: "包裹单号",
    skuCode: "SKU",
    clientRem: "客户备注",
    rem: "订单备注",
    goodsTypeId: "货物类型",
    timerId: "时效",
    carrierId: "承运商",
    goodsCount: "货物数量",
    goodsName: "货物品名",
    goodsNameEn: "英文品名",
    destinationId: "目的地",
    province: "省份",
    city: "城市",
    area: "区域",
    collectionAmount: "代收货款",
    insuredAmount: "报价金额",
    weight: "实际重量",
    isTax: "是否包税",
    agentPointId: "自提点",
    idCard: "身份证号码",
    postNo: "邮政编码",
    receiveName: "收件人姓名",
    receiveAddress: "收件人地址",
    receivePhoneNo: "收件人手机",
    receiveTel: "收件人电话",
    email: "邮件",
    declareName: "申报人姓名",
    declareAddress: "申报人地址",
    declareTel: "申报人电话",
    declarePhoneNo: "申报人手机",
    declareIdCard: "申报统编",
    declarePrice: "申报单价",
    declareCount: "申报数量",
    declareValue: "申报价值",
    length: "长",
    width: "宽",
    height: "高",
    createTime: "下单时间",
    dialogTitle: "提示",
    warehouseLabel: "所属仓库",
    submitButton: "确认提交",
    placeholderWarehouse: "请选择仓库",
    templateName: "模板{0}.xlsx",
    downloadError: "下载失败：{0}",
    downloadSuccess: "下载成功",
    exportSuccess: "导入成功！！",
    validateError: "存在异常数据，请检查后提交",
    prompt: "提示",
    message: "此操作将删除订单号为（{0}）的表格数据, 是否继续?",
    confirm: "确认",
    cancel: "取消",
    deleteSuccess: "删除成功！"
  },
  login: {
    title: '战北物流',
    subTitle1: '让运营更简单',
    subTitle2: '货着急找站北',
    subTitle3: '3000+企业的合作伙伴',
    subTitle4: '为你的满意刷新我的速度',
    login: '会员登录',
    account: '用户名',
    password: '密码',
    register: '注册',
    forgetPassword: '忘记密码',
    submit: '登录',
    placeholderAccount: '请输入用户名',
    placeholderPassword: '请输入密码'
  },
  register: {
    title: '战北物流',
    subTitle1: '让运营更简单',
    subTitle2: '货着急找站北',
    subTitle3: '3000+企业的合作伙伴',
    subTitle4: '为你的满意刷新我的速度',
    item: '成为{0}会员',
    account: '用户名',
    password: '密码',
    phone: '手机号码',
    aws: '淘宝旺旺号，亚马逊网店账号',
    notice: '同意接受本站所发出的所有电邮',
    term: '接受{0}',
    termTitle1: '[服务及条约]',
    termTitle2: '[承運契約條款]',
    termTitle3: '[注意事項]',
    termTitle4: '[失件處理]',
    termUse: '的条款',
    hasAccount: '已有账号登录',
    submit: '注册',
    placeholderAccount: '请输入用户名',
    placeholderPassword: '请输入密码',
    placeholderPhone: '请输入手机号',
    mainlandPhone: '请输入11位的手机号',
    twPhone: '请输入8位的手机号',
    placeholderTerm: '请阅读条款并接受！',
    success: '注册成功！',
    agent: "代理用户ID",
    recommender: "推荐好友ID",
    email: "邮箱",
    prompt: "服务及条约"
  },
  message: {
    couponsState: {
      upToDate: '最新公告',
      preferential: '优惠活动',
    },
    releaseTime: "发布时间",
    date: "时间",
    content: "标题内容",
    activeTime: '活动时间'
  },
  contraband:{
    title:"运输及仓储违禁品",
  },
  losthandle:{
    title:"失件处理",
  },
  problem:{
    domestic:"国内集运",
    foreign:"海外集运",
  },
  shippingcalculation:{
    title:"运费计算",
    destination:"目的地",
    goodtype:"货物类型",
    volume:"体积（单位）",
    length:"请输入货品长度",
    width:"请输入货品宽度",
    height:"请输入货品高度",
    weight:"请输入货品重量",
    submit:"计算",
    result:"运费计算结果",
    warehouseName:"仓库",
    dialogItems: {
      goodsType: '货物类型',
      timer: '时效',
      carrier: '承运商',
      fee: '价格(RMB)',
      lengthExceed: '超长费(RMB)',
      carryWeight: '计算重量(KG)'
    },
    rules: {
      selectWarehouse: '请选择仓库',
      selectDestination: '请选择目的地',
      selectGoodType: '请选择货物类型',
      enterLength: '请输入货品长度',
      enterWidth: '请输入货品宽度',
      enterHeight: '请输入货品高度',
      enterWeight: '请输入货品重量'
    },
  },
  teach:{
    title:"註冊/登記帳戶",
    title1: "影片教学",
    title2: "图文教学",
    loading: "加载中"
  },
  volume:{
    title:"体积计算",
    length:"长度",
    width:"宽度",
    height:"高度",
    fillLength: '请填写长度',
    fillWidth: '请填写宽度',
    fillHeight: '请填写高度',
    lengthplaceholder:"请输入货品长度",
    widthplaceholder:"请输入货品宽度",
    heightplaceholder:"请输入货品高度",
    compute:"计算",
    result:"计算结果",
    notice1: '01: 加收區域或偏遠地區附加費, 請點擊進入各香港配送商了解收費。',
    notice2: '02: 多件集運貨物數量沒有限制。',
    notice3: '03: 寄出的貨品邊長不能過200CM, 總重不能超過300KG。',
    notice4: '04: 如單件貨物的重量超過45KG, 因為搬運困難, 快遞公司會額外收取50-300RMB不等的費用, 單件重量越重, 超重費用越高。',
    notice5: '05: 如單件貨物的邊長超過150CM, 因為派送車輛限制, 快遞公司會額外收取50-300RMB不等的費用, 單件貨物邊長越長, 超長費用越高。',
  },
  footerBox: {
    title1: '探索无限可能',
    title2: '一键下载我们的APP',
    title3: '让集运更便捷！',
    installIOS: '安装iOS',
    installAndroid: '安装安卓',
    features1: '货物管理 订单管理  最新消息 即时通讯',
    features2: '地图搜索自取点',
    features3: '即时物流跟踪',
    // 其他翻译...
  },
  noticelayout:{
    title:"集运须知"
  },
  layout: {
    consolidation: {
      tutorial: '新手教学',
      shippingCalculation: '运费试算',
      volumeConversion: '体积换算',
      precautions: '注意事项',
      faq: '常见问题',
      prohibitedItems: '運輸及倉儲違禁品'
    },
    service: {
      aboutUs: '关于我们',
      privacyStatement: '隐私权保护声明',
      termsAndDisclaimer: '使用条款及免责声明',
      carriageAgreement: '承运条约',
      contactUs: '联系我们'
    },
    member: {
      overview: "会员信息总览",
      accountSettings: "账户设置",
      flow: "钱包流水",
      AgencyPromotion: "代理和推广",
      AgentCenter: "代理中心",
      recommend: "推荐好友",
      myCoupons: "我的优惠券",
      privateMessages: "私信",
      myDomesticWarehouse: "集运管理",
      pickupPoint: "自提点供应",
      domesticWarehouseAddress: "我的收货地址",
      claimGoods: "包裹预报",
      mergeGoods: "合并货物",
      orderList: "订单列表",
      myOverseasWarehouse: "我的海外仓",
      overseasWarehouseAddress: "海外仓地址",
      goodsDeclaration: "货物申报",
      mergeGoodsOverseas: "合并货物",
      myOrdersOverseas: "我的订单",
      notificationSettings: "通知设置",
      addedType: "增值服务类型",
      addedList: "增值服务列表",
      AddedService: "增值服务",
      shopee: '虾皮相关',
      shopeeShop: '店铺管理',
      shopeeOrder: '订单管理',
      line: "专线管理",
      same: "同行相关"
    }
  },
  service:{
    title:"收费及服务",
    aboutUs: '关于我们',
    privacyStatement: '隐私权保护声明',
    termsAndDisclaimer: '使用条款及免责声明',
    carriageAgreement: '承运条约',
    contactUs: '联系我们'
  },
  rich:{
    title:"帮助中心",

  },
  advantagebox:{
    title:"我们的优势"
  },
  corebox:{
    title:"服务宗旨",
    title1: "高效且安全",
    notices1: "我们致力于优化物流流程，提供快速响应、严格执行各项安全措施，采取高标准的包装、装卸作业，并提供全面的货物保险服务，确保货物在整个物流链中的安全与准时",
    title2: "质量保证",
    notices2: "坚持以高品质的服务标准为客户创造价值，利用先进的信息系统和专业的物流团队，实时跟踪货物动态，确保物流信息的准确性和透明度",
    title3: "个性且环保",
    notices3: "针对不同的行业特性和客户需求，提供专业、环保、防损的个性化包装方案,提供多种运输保险选项等增值服务。积极履行社会责任，倡导绿色物流理念，努力降低物流过程中的环境污染和能源消耗",
    title4: "持续改进",
    notices4: "紧跟行业发展趋势，不断引入新的物流技术和管理模式，持续优化服务体系，致力于为客户提供与时俱进的优质国际物流服务体验。"
  },
  logistics:{
    search:"查詢",
    records:"历史记录",
    clearrecord:"清空记录",
    placeholder: "請輸入運單號"
  },
  account:{
    passwordChangeSuccess: '密码修改成功，请重新登录',
    enterCorrectPhoneNumber: '请输入正确的手机号',
    copySuccess: '复制成功！',
    copyTextFailed: '复制文本失败',
    confirmDelete: '此操作将永久删除该账号，是否继续?',
    prompt: '提示',
    confirm: '确定',
    enterPassword: '请输入密码',
    deleteAccount: '注销账号',
    passwordNotEmpty: '密码不能为空',
    deleteRequestSuccess: '注销申请提交成功！在{time}之前登录视作取消注销',
    userChangeSuccess: '修改用户信息成功！',
    editAvatar: "修改头像",
    filters: {
      female: '女',
      male: '男',
      secret: '保密',
      unlimited: "不限",
    },
    rules: {
      enterOldPassword: '请输入原密码',
      passwordLength: '密码长度不能低于6位',
      enterNewPassword: '请输入新密码',
      passwordMismatch: '两次输入的密码不一致',
      enterUserName: '请输入用户名',
      selectGender: '请选择性别',
      enterEmail: '请输入邮箱',
      enterPhoneNumber: '请输入手机号'
    },
    listData: {
      memberNumber: '会员编号',
      taobaoAccount: '淘宝账号',
      userName: '用户昵称',
      gender: '性别',
      email: '电邮',
      loginPassword: '登录密码',
      phoneNumber: '电话',
      avatar: '头像'
    },
    title:"个人信息设置",
    accountsecurity:"账号安全",
    password:"修改密码",
    mailbox:"验证邮箱",
    modifyemail:"修改邮箱",
    cancellation:"账号注销",
    editaccount:"编辑账户信息",
    name:"用户昵称",
    sex:"性别",
    pleaseselect:"请选择",
    secrecy:"保密",
    male:"男",
    female:"女",
    unlimited: "不限",
    email:"电邮",
    phone:"电话",
    phonenumber:"手机号码",
    confirmupdate:"确认修改",
    cancel:"取 消",
    oldpassword:"原密码",
    newpassword:"新密码",
    confirmpassword:"确认密码",
  },
  address:{
    common: {
      prompt: '提示',
      confirm: '确定',
      cancel: '取消',
    },
    AddressSelect: {
      title: "收货人信息",
      add: "新增收货地址",
      expand: "展开",
      edit: "编辑",
      delete: "删除",
      retract: "收起地址"
    },
    pasteAddress: "地址粘贴板",
    identifyAddress: "自动识别地址",
    placeholderPasteAddress: "试试粘贴收件人姓名、手机号、收货地址、可快速识别您的收货信息",
    identify: "识别",
    selectServiceType: '请选择服务类型',
    confirmDelete: '此操作将永久删除该地址, 是否继续?',
    deleteSuccess: '删除成功!',
    addSuccess: '添加地址成功！',
    editSuccess: '修改地址成功！',
    areas: "区域",
    SetDefaultAddress: '设为默认',
    defaultSuccess: "设置成功",
    TypeText: "类型",
    editAddress: '编辑地址',
    addAddress: '新增地址',
    selectagent: '自提点',
    selectCountry: '请选择国家',
    selectProvince: '请选择省份',
    selectCity: '请选择城市',
    selectDistrict: '请选择区县',
    enterRecipientInfo: '请输入收件人信息',
    enterContactPhone: '请输入联系人电话',
    enterDetailedAddress: '请输入详细地址',
    IsDefaultAddress: "默认地址",
    selectAgentPoint: '请选择自提点地址',
    pickup: "自提",
    dispatch: "派送",
    serviceType: "服务类型",
    title:"我的收货地址",
    all:"全部地址",
    recipientname:"收件人姓名",
    recipientphone:"联系电话",
    recipientaddress:"详细地址",
    destinationname:"目的地",
    operate:"操作",
    edit:"编辑",
    delete:"删除",
    add:"添加地址",
    disposition:"配置管理",
    role:"角色管理",
    nodistrict:"非工商区",
    district:"工商区",
    destination:"目的地",
    selectdestination:"请选择目的地",
    province:"省份",
    selectprovince:"省",
    city:"城市",
    selectcity:"市",
    area:"区县",
    selectarea:"区县",
    cancel:"取 消",
    confirmupdate:"确认修改",
    Confirmaddition:"确认新增",
    Dispatch: "派送",
    SelfPickup: "自提"
  },
  singlecard:{
    code:"请输入快递单号",
    goodsname:"请输入品名",
    goodstype:"货物类型",
    account:"货物数量",
    price:"货物单价",
    totalprice:"货物总价",
    clientrem:"备注说明",
    warehouseNumber: "入仓号"
  },
  apply:{
    warehouseNumber: '入仓号',
    enterExpressNumber: '请输入快递单号',
    enterGoodsName: '请输入货物名称',
    selectGoodsType: '请选择货物类型',
    enterGoodsQuantity: '请输入货物数量',
    enterUnitPrice: '请输入货物单价',
    enterTotalPrice: '请输入货物总价',
    enterInformation: '请填写信息',
    fillInCompleteInformation: '请将信息填写完整',
    reportSuccess: '上报成功！',
    noticeTitle: '温馨提示',
    notice1: '預報運單是當您在購物網站訂單成功后，獲得購物網站確認的物流發貨單號。預報給我們會提高倉儲收發包裹管理，更加高效地處理您的包裹。',
    notice2: '【普货】无牌，无电，无磁性的电子产品及配件和服装类..…',
    notice3: '【食品专线】食品渠道不能走纯液体，纯粉末，电池，马达，肉类，蛋黄类等物品',
    notice4: '【特A】行车记录仪、播放器、故事碟、DVD、DV、U盘 MP3/45手表、马达电池，遥控玩具、电动车玩具、对讲机、音箱 仿牌.....隐形眼镜，美瞳、茶叶化妆品、食品 液体 粉类……',
    notice5: '【特C】药品平板电脑 手机 .……',
    title:"申请预报",
    individualforecast:"逐个预报",
    batchforecast:"批量预报",
    code:"快递单号",
    goodsname:"货物名称",
    goodstype:"货物类型",
    account:"货物数量",
    price:"货物单价",
    totalprice:"货物总价",
    clientrem:"备注说明",
    add:"添加",
    remove:"移除",
    submit:"提交"
  },
  added: {
    type: "服务对象",
    name: "服务名称",
    search: "查询",
    TypeTxt: "服务类型",
    Price: "服务费用",
    Rem: "服务说明",
    order: "订单",
    package: "包裹",
    PayState: "付款状态",
    PayTime: "付款时间",
    IsHandle: "处理状态",
    HandleTime: "处理时间",
    CreateTime: "创建时间",
    PackageType: "单号类型",
    paid: "已付款",
    unpaid: "未付款",
    handle: "已处理",
    Unprocessed: "未处理",
    Code: "单号",
    placeholderCode: "订单/包裹编号",
    OrderId: "编号",
    orderCode: "订单号",
    billCode: "快递单号"
  },
  goodcard:{
    goodsname:"包裹名称",
    weight:"重量(KG)",
    code:"快递单号",
    volumeweight:"体积重(KG)",
    size:"尺寸",
    day: "天",
    remaining: "剩余可免存",
    ProblemTypeName: "问题件类型",
    Rem: "说明",
    HandleStateName: "处理状态",
    HandleRem: "处理说明",
    HandleTime: "处理时间",
    GoodsTypeName: "货物类型",
    Account: "数量"
  },
  domestic:{
    showImage: "查看图片",
    confirmDelete: '此操作将永久删除包裹（{goodsName}），是否继续?',
    placeholder: "请输入快递单号，多个用空格隔开",
    search: "搜索",
    CreateTime: "创建时间",
    prompt: '提示',
    confirm: '确定',
    deleteSuccess: '删除成功',
    cancelDelete: '已取消删除',
    updateSuccess: '修改成功！',
    selectMergePackages: '请先选择要合并下单的包裹！',
    diffWarehouse: '不能申请不同的仓库！',
    unstoredGoods: '不能申请未入库的货物！',
    UpdateRules: {
      GoodsName: {
        required: '请输入货物名称',
      },
      GoodsTypeName: {
        required: '请选择货物类型',
      },
      Account: {
        required: '请输入货物数量',
      },
      Price: {
        required: '请输入货物价格',
      },
    },
    all:"所有货物",
    goodsinfo:"货物信息",
    status:"仓库状态",
    waitPayment: "待下单",
    arrived:"已到仓",
    unarrive:"未到仓",
    claim: '待认领',
    forecast: '认领',
    goodClaim: '货物认领',
    account:"数量(件)",
    warehousename:"仓库名称",
    operate:"操作",
    edit:"编辑",
    delete:"删除",
    selection:"已选",
    addup:"累计(KG)",
    overlength:"超长(M)",
    combine:"合并下单",
    disposition:"配置管理",
    role:"角色管理",
    combinefleight:"合并货运",
    editinfo:"编辑信息",
    goodsname:"包裹名称",
    goodstype:"货物类型",
    selectgoodstype:"请选择货物类型",
    goodsaccount:"数量",
    goodsprice:"价格",
    clientrem:"备注",
    confirmupdate:"确认修改",
    cancel:"取 消",
  },
  consolidation: {
    noticeTitle: '注意事项',
  },
  coupon:{
    periodOfValidity: "有效期",
    to: "至",
    integral: "积分",
    exchangeSuccess: "兑换成功",
    effectivetime:"生效时间",
    expiredtime:"过期时间",
    use:"立即使用",
    pointscashroll:"积分",
    cashroll:"现金卷",
    pointexchange:"积分兑换",
    exchange:"兑换",
    discountcoupon:"优惠券",
    title: '使用积分兑换优惠券, 是否继续?',
    notice: "至兑换日起{0}天内有效",
    minPrice: "最低使用价格",
    MinWeight: "最低使用重量"
  },
  declare:{
    rules: {
      courier: {
        required: '请输入快递单号',
      },
      storehouse: {
        required: '请选择海外仓库',
      },
      company: {
        required: '请选择快递公司',
      },
      type: {
        required: '请选择货物种类',
      },
      classification: {
        required: '请选择种类分类',
      },
      goodsName: {
        required: '请输入货物名称',
      },
      goodsNum: {
        required: '请输入货物数量',
      },
      currency: {
        required: '请选择种类币',
      },
      value: {
        required: '请填写货物价值',
      },
    },
    title:"海外到香港的貨物 申報",
    code:"快递单号",
    codeplaceholder:"请输入快递单号",
    findcode:"如何找到快递运单号？",
    storehouse:"海外仓库",
    company:"快递公司",
    type:"货物种类",
    classification:"种类分类",
    goodsname:"货物名称",
    goodsNum:"货物数量",
    currency:"币种",
    value:"货物价值",
    reset:"重置",
    submit:"提交",
    placeholderMark: "备注：说明文字描述",
    notice1: '申報貨物時, 請正確選擇海外倉庫, 單號正確而倉庫不正確的話, 狀態會一直顯示未簽收。',
    notice2: '貨物名稱輸入請使用英文名稱。',
  },
  common: {
    title: "评论",
    submit: "提交",
    imageValidate: "图片大小不能超过 5MB!"
  },
  notify:{
    title:"APP推送消息",
    emailmsg:"Email通知",
    unverified:"检测到您的邮箱未进行验证",
    latestnotice:"最新优惠通知",
    intelligentservice:"智能服务",
    rem:"货物到达仓后会自动安排到你的账户内"
  },
  RightBox: {
    title: "回到顶部"
  },
  line: {
    title: "专线下单",
    loadingText: "表单提交中...",
    ClientCode: "客户单号",
    GoodsName: "货物品名",
    DestinationId: "目的地",
    placeholder: "请选择",
    GoodsTypeId: "货物类型",
    CarrierId: "承运商",
    TimerId: "时效",
    WareHouseId: "收货仓库",
    RecipientName: "收件人",
    RecipientPhone: "收件手机",
    RecipientEmail: "收件邮箱",
    RecipientPostCode: "收件邮编",
    RecipientAddress: "收件地址",
    Rem: "备注",
    submitForm: "立即下单",
    resetForm: "重置",
    placeholderDestinationId: "请选择目的地",
    placeholderGoodsTypeId: "请选择货物类型",
    placeholderCarrierId: "请选择承运商",
    placeholderTimerId: "请选择时效",
    placeholderWareHouseId: "请选择仓库",
    placeholderClientCode: "请输入客户单号",
    placeholderGoodsName: "请输入货物品名",
    placeholderRecipientAddress: "请输入收件地址",
    placeholderRecipientPhone: "请输入收件手机",
    placeholderRecipientName: "请输入收件人",
    submitSuccess: "下单成功！！！",
    length: "长",
    width: "宽",
    height: "高",
    quantity: "数量",
    grossWeight: "毛重",
    tareWeight: "皮重",
    channel: "渠道",
    customsClearance: "是否报关",
    uploadBoxForm: "上传箱单",
    downloadBoxForm: "下载箱单模板",
    uploadTip: "只能上传excel文件，且不超过3M",
    addPackage: "添加包裹信息",
    packageWeight: "包裹重量信息",
    grossWeightLabel: "毛重（KG）",
    tareWeightLabel: "皮重（KG）",
    packageSize: "包裹尺寸信息",
    lengthLabel: "长（CM）",
    widthLabel: "宽（CM）",
    heightLabel: "高（CM）",
    packageInfo: "包裹信息",
    packageTip: "货物信息，不入仓货物必填",
    confirm: "取 消",
    cancel: "确 定",
    yes: "是",
    no: "否"
  },
  order:{
    deleteOrder: {
      confirm: '此操作将永久删除编号为（{orderId}）的订单，是否继续?',
      prompt: '提示',
      confirmText: '确定',
      cancelText: '取消',
      success: '删除成功',
      cancelDelete: '已取消删除',
    },
    deliveryOrder: {
      confirm: '此操作将确认收货编号为（{0}）的订单，是否继续?',
      prompt: '提示',
      confirmText: '确定',
      cancelText: '取消',
      success: '收货成功',
      cancel: '已取消收货',
    },
    shipmentCode: "发货单号",
    delivery: "确认收货",
    trajectory: '物流记录',
    confirm: '确认',
    emptyTrajectory: "没有物流记录",
    CreateTime: "下单时间",
    placeholder: "请输入订单号",
    Yes: '是',
    No: "否",
    rate: '评价',
    Weight: "重量(KG)",
    title:"订单信息",
    mainbillcode:"订单号",
    id:"编号",
    orderid:"订单编号",
    export: "导出",
    exportOrder: "订单导出",
    separator: "至",
    startTime: "开始日期",
    endTime: "结束日期",
    carrierbillcode:"承运单号",
    goodsname:"品名",
    receiveinfo:"收件人信息",
    receivename:"收件人名",
    receivephone:"联系手机",
    receivetelephone:"联系电话",
    receiveaddress:"收件地址",
    receiveidcard:"统编",
    receivepostcode:"邮编",
    recipientaddress:"收货地址",
    destination:"目的地",
    selectdestination:"请选择目的地",
    province:"省份",
    selectprovince:"请选择省份",
    city:"城市",
    selectcity:"请选择城市",
    area:"区县",
    selectarea:"请选择区县",
    warehouse:"仓库",
    goodstype:"货物类型",
    timer:"时效",
    carrier:"承运商",
    orderrem:"下单备注",
    deliverytitle:"发货信息",
    ordercode:"快递单号",
    logistics:"物流公司",
    packagetitle:"订单包裹信息",
    rem:"备注",
    account:"数量",
    daysinstorage:"到库天数",
    size:"尺寸",
    weight:"实际重量",
    surcharge:"附加费",
    count:"总件数",
    grossweight:"总重量",
    grosssurcharge:"总附加费",
    storagecharges:"仓租",
    weightcharges:"超重费用",
    lengthcharges:"超长费用",
    fareweight:"总计费重量",
    receivinginfo:"收货人信息",
    declarationinfo:"申报人信息",
    declarename:"申报人名",
    declarePhone:"联系手机",
    declaretelephone:"联系电话",
    declareidcard:"统编",
    declarepostcode:"邮编",
    declareaddress:"申报地址",
    declareaccount:"申报数量",
    declareprice:"申报价格",
    feeinfo:"费用信息",
    collectionamount:"代收货款",
    taxfee:"包税费",
    insuredamount:"保价金额",
    couponamount:"优惠券",
    integralamount:"积分抵扣金额",
    destinationcharges:"目的地费用",
    istax:"是否包税",
    istopay:"是否到付",
    totalfee:"费用合计",
    freight:"运费",
    collectioncommission:"代收贷款手续费",
    insuredcommission:"保价手续费",
    addresssurcharge:"地址附加费",
    addedservicefee:"增值服务费用",
    discount:"享受折扣",
    off:"折",
    accruedcharge:"目的国货币({0})",
    paytype:"支付方式",
    payment:"付款",
    paystate:"付款状态",
    cashier: "收银台",
    paid:"已付款",
    nonpayment:"未付款",
    createtime:"下单时间",
    printer:"打印",
    domestic:"所有订单",
    operate:"操作",
    details:"详情",
    delete:"删除",
    selection:"已选",
    deleteorder:"删除订单",
    disposition:"配置管理",
    role:"角色管理",
    orderdetails:"订单详情",
    underway:"待发货",
    shipped: "已发货",
    completed:"已完成",
    unPaid: "待付款",
    orderSourceName: "订单来源"
  },
  recharge: {
    title: "余额充值",
    balance: "余额",
    money: "充值金额",
    confirmMoney: "金额",
    submit: "确认充值",
    warning: "请选择充值金额！",
    inventory: {
      title: "订单详情：余额充值",
      balance: "账户余额",
      pon: "虚拟订单号"
    }
  },
  pickupPoint: {
    Name: "自提点名称",
    TypeText: "类型",
    DestinationName: "目的地",
    ProvinceName: "省",
    CityName: "城",
    AreaName: "区域",
    Address: "详细地址",
    Phone: "联系手机",
    Tel: "联系电话",
    BusinessHours: "营业时间",
    Postcode: "邮编",
    ServiceCharge: "服务费",
    StorageLife: "存储期",
    Longitude: "经度",
    Latitude: "纬度",
    Rem: "备注说明",
    AgentStateText: "开发状态",
    Add: "添加至收货地址",
  },
  personal:{
    withdrawalMoney: {
      Amount: "提现金额",
      BankName: "开户银行",
      OpenName: "开户姓名",
      BankCard: "银行卡号",
      submit: "申请提现",
      cancel: "取消",
      title: "余额提现(账户余额：{0})",
      placeholderAmount: "请填写提现金额",
      placeholderOpenName: "请填写开户姓名",
      placeholderBankCard: "请填写银行卡号",
      placeholderBankName: "请填写开户银行",
      withdrawalSuccess: "提现申请已提交！"
    },
    Rem: "备注",
    sign: "签到",
    signCalendar: "签到日历",
    signSuccess: "签到成功奖励积分({0})",
    withdrawalMoneyTitle: "提现",
    package: "我的包裹",
    order: "我的订单",
    notice: "公告",
    confirm: "确 定",
    balance: "余额充值",
    domestic:"国内仓",
    address: "我的收货地址",
    recharge: "充值",
    arrived:"已到仓",
    recommend: "推荐好友",
    commission: "赚取佣金",
    withdrawal: "可提现佣金",
    unarrive:"未到仓",
    notplaceorder:"待下单",
    placedorder:"待认领",
    domesticaddress:"国内仓专属地址",
    cargoclaim:"货物认领",
    combinedcargo:"合并货物",
    myorder:"我的订单",
    username:"会员名称",
    amount: "账户余额",
    membernumber:"会员号",
    accountnumber:"账号",
    deliveryaddress:"收货地址",
    mycoupon:"我的优惠券",
    recommendedfriend:"推荐好友",
    integral:"积分",
    coupon:"可用优惠券",
    membercenter:"集运中心",
    ContactPerson: "联络人名",
    Phone: "联系电话",
    PostCode: "邮政编码",
    DetailedAddress: "收货地址",
    copyAddress: "一键复制地址",
    title: "集运仓专属地址",
    pendingPayment: "待付款",
    toBeShipped: "待发货",
    shipped: "已发货",
    completed: "已完成",
  },
  procedure:{
    demonstrate: {
      step1: {
        title: '注册账号成为会员',
        subTitle: ''
      },
      step2: {
        title: '获得并选定集运仓地址作为购物平台的收货地址',
        subTitle: ''
      },
      step3: {
        title: '将购买好的货物认领到本网站平台',
        subTitle: ''
      },
      step4: {
        title: '货物到仓库后并申请发货',
        subTitle: ''
      },
      step5: {
        title: '货物到达目的地，自提或派送上门',
        subTitle: ''
      }
    },
    servers: {
      step1: {
        title: '货物自提',
        introduces: [
          '超300+自提合作网点,选择最适合你的一家提货!\n' +
          '服务优点:节省成本、灵活时间安排、控制交货流程',
          '·  将货物发至指定的物流点。',
          '· 物流公司将货物送达后通知收货人。',
          '· 收货人携带有效身份证明以及取件码或快递单号等相关信息，前往指定地点办理提货手续。'
        ]
      },
      step2: {
        title: '派送到门',
        introduces: [
          '快递员在约定的时间范围内送达包裹',
          '并与收件人完成签收手续'
        ]
      },
      step3: {
        title: '物流轨迹实时跟踪',
        introduces: [
          '当订单轨迹状态发生变化时，系统会实时更新并同步至我们的查询平台',
          '会员中心或手机APP上输入唯一的物流单号，即可查看详细的订单轨迹',
          '全天候24小时可用，无论何时何地，您都可以随时查询了解货物的位置和状态'
        ]
      },
      // 其他翻译...
    },
    process:"集运流程",
    learnmore:"了解更多",
    checkMore: "查看仓库地址",
    warehouseIntroduction: '集运仓库',
    warehouseLocation: '拥有总面积超过10000平方米的现代化仓库,分区明确，适应不同类型商品存储需求,地理位置位于交通枢纽地带，紧邻港口、机场或主要公路网络，确保货物能够快速、便捷地进行集散和转运，大大缩短运输时间，降低物流成本',
    warehouseFunction: '采用先进的自动化立体仓库技术，实现高效精准的入库、出库及库存管,24小时全天候视频监控、消防设施完备，并配备专业的保安团队，确保货物安全无虞',
    learnMore: '了解更多',
    weightCalculation: '计费重量',
    weightCalculationNote1: '运费计算中，收费重量基于两个要素：实际毛重和体积重量。实际毛重是指货物实体的重量；体积重量则是考虑包裹虽轻但体积大时的一种等效重量计算方式。',
    weightCalculationNote2: '实际毛重：直接称量货物得出。',
    weightCalculationNote3: '体积重量：通过公式计算（长x宽x高 ÷ 计费因子），并根据运输方式有不同的计费因子标准。',
    weightCalculationNote4: '运费将以实际毛重与体积重量两者中较大的数值作为计费基础。此外，对于部分特殊货物或服务，可能还会有最低计费重量、超大超重附加费等额外规定。如有疑问或需要详细报价，请随时联系我们的客服团队。',
    declarationTitle: '货物申报',
    declarationNote1: '确保所有货物信息如品名、规格、数量、价值等与实际货物完全一致，并提供完整且真实的商业发票、装箱单和相关证书,以便货物进出口环节顺畅进行申报，有效降低通关风险',
    shippingPackage: '增值服务',
    packageNote1: '如果你的貨物因太泡而需要折除包裝，又或外包裝屬於多角形狀，無法擠進國際集運包裝箱，那麼我們提供免費的重新包裝服務。重新包裝後體積會相應減少，但不是絕對。',
  }
}
